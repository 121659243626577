@font-face {
  font-family: 'Flama Basic';
  src: url("../../assets/fonts/Flama-Basic.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Basic.woff2") format("woff2"), url("../../assets/fonts/Flama-Basic.woff") format("woff"), url("../../assets/fonts/Flama-Basic.ttf") format("truetype");
}

@font-face {
  font-family: 'Flama Black';
  src: url("../../assets/fonts/Flama-Black.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Black.woff2") format("woff2"), url("../../assets/fonts/Flama-Black.woff") format("woff"), url("../../assets/fonts/Flama-Black.ttf") format("truetype");
}

@font-face {
  font-family: 'Flama Bold';
  src: url("../../assets/fonts/Flama-Bold.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Bold.woff2") format("woff2"), url("../../assets/fonts/Flama-Bold.woff") format("woff"), url("../../assets/fonts/Flama-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Flama Book';
  src: url("../../assets/fonts/Flama-Book.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Book.woff2") format("woff2"), url("../../assets/fonts/Flama-Book.woff") format("woff"), url("../../assets/fonts/Flama-Book.ttf") format("truetype");
}

@font-face {
  font-family: 'Flama Extrabold';
  src: url("../../assets/fonts/Flama-Extrabold.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Extrabold.woff2") format("woff2"), url("../../assets/fonts/Flama-Extrabold.woff") format("woff"), url("../../assets/fonts/Flama-Extrabold.ttf") format("truetype");
}

@font-face {
  font-family: 'Flama Light';
  src: url("../../assets/fonts/Flama-Light.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Light.woff2") format("woff2"), url("../../assets/fonts/Flama-Light.woff") format("woff"), url("../../assets/fonts/Flama-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Flama Medium';
  src: url("../../assets/fonts/Flama-Medium.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Medium.woff2") format("woff2"), url("../../assets/fonts/Flama-Medium.woff") format("woff"), url("../../assets/fonts/Flama-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Flama Semibold';
  src: url("../../assets/fonts/Flama-Semibold.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Semibold.woff2") format("woff2"), url("../../assets/fonts/Flama-Semibold.woff") format("woff"), url("../../assets/fonts/Flama-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: 'Flama Thin';
  src: url("../../assets/fonts/Flama-Thin.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Thin.woff2") format("woff2"), url("../../assets/fonts/Flama-Thin.woff") format("woff"), url("../../assets/fonts/Flama-Thin.ttf") format("truetype");
}

@font-face {
  font-family: 'Flama Ultralight';
  src: url("../../assets/fonts/Flama-Ultralight.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Ultralight.woff2") format("woff2"), url("../../assets/fonts/Flama-Ultralight.woff") format("woff"), url("../../assets/fonts/Flama-Ultralight.ttf") format("truetype");
}

@font-face {
  font-family: 'FlamaSemicondensed Basic';
  src: url("../../assets/fonts/FlamaSemicondensed-Basic.eot") format("embedded-opentype"), url("../../assets/fonts/FlamaSemicondensed-Basic.woff2") format("woff2"), url("../../assets/fonts/FlamaSemicondensed-Basic.woff") format("woff"), url("../../assets/fonts/FlamaSemicondensed-Basic.ttf") format("truetype");
}

@font-face {
  font-family: 'FlamaSemicondensed Black';
  src: url("../../assets/fonts/FlamaSemicondensed-Black.eot") format("embedded-opentype"), url("../../assets/fonts/FlamaSemicondensed-Black.woff2") format("woff2"), url("../../assets/fonts/FlamaSemicondensed-Black.woff") format("woff"), url("../../assets/fonts/FlamaSemicondensed-Black.ttf") format("truetype");
}

@font-face {
  font-family: 'FlamaSemicondensed Bold';
  src: url("../../assets/fonts/FlamaSemicondensed-Bold.eot") format("embedded-opentype"), url("../../assets/fonts/FlamaSemicondensed-Bold.woff2") format("woff2"), url("../../assets/fonts/FlamaSemicondensed-Bold.woff") format("woff"), url("../../assets/fonts/FlamaSemicondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'FlamaSemicondensed Book';
  src: url("../../assets/fonts/FlamaSemicondensed-Book.eot") format("embedded-opentype"), url("../../assets/fonts/FlamaSemicondensed-Book.woff2") format("woff2"), url("../../assets/fonts/FlamaSemicondensed-Book.woff") format("woff"), url("../../assets/fonts/FlamaSemicondensed-Book.ttf") format("truetype");
}

@font-face {
  font-family: 'FlamaSemicondensed Extrabold';
  src: url("../../assets/fonts/FlamaSemicondensed-Extrabold.eot") format("embedded-opentype"), url("../../assets/fonts/FlamaSemicondensed-Extrabold.woff2") format("woff2"), url("../../assets/fonts/FlamaSemicondensed-Extrabold.woff") format("woff"), url("../../assets/fonts/FlamaSemicondensed-Extrabold.ttf") format("truetype");
}

@font-face {
  font-family: 'FlamaSemicondensed Light';
  src: url("../../assets/fonts/FlamaSemicondensed-Light.eot") format("embedded-opentype"), url("../../assets/fonts/FlamaSemicondensed-Light.woff2") format("woff2"), url("../../assets/fonts/FlamaSemicondensed-Light.woff") format("woff"), url("../../assets/fonts/FlamaSemicondensed-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'FlamaSemicondensed Medium';
  src: url("../../assets/fonts/FlamaSemicondensed-Medium.eot") format("embedded-opentype"), url("../../assets/fonts/FlamaSemicondensed-Medium.woff2") format("woff2"), url("../../assets/fonts/FlamaSemicondensed-Medium.woff") format("woff"), url("../../assets/fonts/FlamaSemicondensed-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'FlamaSemicondensed Semibold';
  src: url("../../assets/fonts/FlamaSemicondensed-Semibold.eot") format("embedded-opentype"), url("../../assets/fonts/FlamaSemicondensed-Semibold.woff2") format("woff2"), url("../../assets/fonts/FlamaSemicondensed-Semibold.woff") format("woff"), url("../../assets/fonts/FlamaSemicondensed-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: 'FlamaSemicondensed Ultralight';
  src: url("../../assets/fonts/FlamaSemicondensed-Ultralight.eot") format("embedded-opentype"), url("../../assets/fonts/FlamaSemicondensed-Ultralight.woff2") format("woff2"), url("../../assets/fonts/FlamaSemicondensed-Ultralight.woff") format("woff"), url("../../assets/fonts/FlamaSemicondensed-Ultralight.ttf") format("truetype");
}

.header-top-area {
  display: none;
}

@media (min-width: 1200px) {
  .main_menu_area {
    min-height: 70px !important;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .main_menu_area {
    min-height: 70px !important;
  }
}

@media (max-width: 991px) and (min-width: 1px) {
  .main_menu_area {
    min-height: 50px !important;
  }
}

nav.navigation .bar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 70px;
  background: white;
  z-index: 998;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(195, 197, 200, 0.2);
}

@media (max-width: 991.98px) {
  nav.navigation .bar {
    height: 50px;
  }
}

nav.navigation .bar .nav-button {
  display: none;
  position: absolute;
  top: 0px;
  right: 0;
  height: 49px;
  width: 50px;
  background: #164A62;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background .3s;
  z-index: 999;
}

nav.navigation .bar .nav-button svg {
  position: absolute;
  transition: opacity .3s;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  nav.navigation .bar .nav-button svg {
    top: 0px;
    right: 15px;
  }
}

nav.navigation .bar .nav-button svg.ham {
  opacity: 1;
}

nav.navigation .bar .nav-button svg.x {
  opacity: 0;
}

@media (min-width: 992px) {
  nav.navigation .bar .nav-button {
    display: none;
  }
}

nav.navigation .bar .bar-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  top: 0px;
  left: 0px;
}

nav.navigation .bar .mcui-brand {
  position: absolute;
  top: -2px;
  left: 40px;
  display: flex;
  align-items: center;
  height: 70px;
}

@media (max-width: 1199.98px) {
  nav.navigation .bar .mcui-brand {
    top: 0;
    left: 10px;
  }
}

@media (max-width: 991.98px) {
  nav.navigation .bar .mcui-brand {
    height: 50px;
    left: 20px;
    top: 0;
  }
}

nav.navigation .bar .nav-content {
  height: 100%;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content {
    display: none;
    position: fixed;
    top: 50px;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 50px;
    background: white;
    opacity: 0;
    transition: opacity .3s;
  }
}

nav.navigation .bar .nav-content .left {
  left: 240px;
  height: 70px;
  display: flex;
  align-items: center;
}

@media (max-width: 1199.98px) {
  nav.navigation .bar .nav-content .left {
    left: 185px;
  }
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .left {
    position: relative !important;
    top: 50px;
    left: 0;
    height: auto;
    width: 100%;
  }
}

nav.navigation .bar .nav-content .left .text-links {
  display: inline-block;
  height: 100%;
  display: flex;
  align-items: center;
  transition: opacity .3s;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .left .text-links {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
}

nav.navigation .bar .nav-content .left .text-links .section-container {
  height: 100%;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .left .text-links .section-container {
    width: 100%;
  }
  nav.navigation .bar .nav-content .left .text-links .section-container .menu {
    min-height: 1px;
    height: 100%;
    top: 50px;
    z-index: 999;
    overflow: scroll;
    background-color: #FFFFFF;
  }
}

nav.navigation .bar .nav-content .left .text-links .section-container > a {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
}

nav.navigation .bar .nav-content .left .text-links .section-container.open .menu {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0%);
  transition: opacity .3s, transform .3s, visibility 0s;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .left .text-links .section-container.open .menu {
    transform: translateX(0%);
    width: 100%;
  }
}

nav.navigation .bar .nav-content .left .text-links .section-container.open .main-link {
  opacity: 1;
}

nav.navigation .bar .nav-content .left .text-links .section-container.open .main-link > span:before {
  visibility: visible;
  transform: scaleX(1);
}

nav.navigation .bar .nav-content .left .main-link {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
  font-style: normal;
  color: #164A62;
  cursor: pointer;
  font-size: 16px;
  transition: opacity .3s;
  display: flex;
  align-items: center;
  opacity: 0.7;
  position: relative;
  height: 100%;
  padding: 0px 20px;
  transition: transform .3s, opacity .3s;
  transition-delay: 0s;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .left .main-link {
    font-size: 16px;
  }
}

nav.navigation .bar .nav-content .left .main-link > svg {
  position: relative;
  transform: rotate(90deg);
  opacity: 0.2;
  right: -10px;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .left .main-link > svg {
    transform: rotate(0deg);
    opacity: 0.6;
    position: absolute;
    right: 30px;
    display: flex;
    align-self: center;
  }
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .left .main-link {
    height: 50px;
    font-size: 2rem;
  }
}

nav.navigation .bar .nav-content .left .main-link > span {
  position: relative;
}

nav.navigation .bar .nav-content .left .main-link > span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #164A62;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

nav.navigation .bar .nav-content .left .main-link:hover > span:before {
  visibility: visible;
  transform: scaleX(1);
}

nav.navigation .bar .nav-content .left .main-link:hover {
  opacity: 1;
}

nav.navigation .bar .nav-content .left .main-link:hover > svg {
  opacity: 0.6;
}

nav.navigation .bar .nav-content .left .main-link.home {
  padding: 0px 20px 0px 0px;
  transform: translateY(-16%);
}

nav.navigation .bar .nav-content .right {
  right: 0px;
  height: 70px;
  display: flex;
  align-items: center;
}

@media (max-width: 1199.98px) {
  nav.navigation .bar .nav-content .right {
    right: 5px;
  }
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right {
    flex-direction: column;
    height: 50px;
  }
}

nav.navigation .bar .nav-content .right .section-container {
  height: 100%;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .section-container {
    position: absolute;
    top: 325px;
  }
}

nav.navigation .bar .nav-content .right .section-container .user.menu {
  right: 50px;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .section-container .user.menu {
    right: 0;
  }
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .section-container {
    width: 100%;
  }
  nav.navigation .bar .nav-content .right .section-container .menu {
    min-height: 1px;
    height: 100%;
    top: 50px;
    z-index: 999;
    overflow: scroll;
  }
}

nav.navigation .bar .nav-content .right .section-container > a {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
}

nav.navigation .bar .nav-content .right .section-container.open .menu {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0%);
  transition: opacity .3s, transform .3s, visibility 0s;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .section-container.open .menu {
    transform: translateX(0%);
    width: 100%;
  }
}

nav.navigation .bar .nav-content .right .section-container.open .main-link {
  opacity: 1;
}

nav.navigation .bar .nav-content .right .section-container.open .main-link > span:before {
  visibility: visible;
  transform: scaleX(1);
}

nav.navigation .bar .nav-content .right .main-link {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
  font-style: normal;
  color: #164A62;
  cursor: pointer;
  font-size: 16px;
  transition: opacity .3s;
  display: flex;
  align-items: center;
  opacity: 0.7;
  position: relative;
  height: 100%;
  padding: 0px 20px;
  transition: transform .3s, opacity .3s;
  transition-delay: 0s;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .main-link {
    font-size: 14px;
  }
}

nav.navigation .bar .nav-content .right .main-link > svg {
  position: relative;
  transform: rotate(90deg);
  opacity: 0.2;
  right: -10px;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .main-link > svg {
    transform: rotate(0deg);
    opacity: 0.6;
    position: absolute;
    right: 30px;
    display: flex;
    align-self: center;
  }
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .main-link {
    height: 50px;
    font-size: 2rem;
  }
}

nav.navigation .bar .nav-content .right .main-link > span {
  position: relative;
}

nav.navigation .bar .nav-content .right .main-link > span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #164A62;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

nav.navigation .bar .nav-content .right .main-link:hover > span:before {
  visibility: visible;
  transform: scaleX(1);
}

nav.navigation .bar .nav-content .right .main-link:hover {
  opacity: 1;
}

nav.navigation .bar .nav-content .right .main-link:hover > svg {
  opacity: 0.6;
}

nav.navigation .bar .nav-content .right .main-link.home {
  padding: 0px 20px 0px 0px;
  transform: translateY(-16%);
}

nav.navigation .bar .nav-content .right .search-link {
  transform: translateX(0px);
  transition: transform .3s;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .search-link {
    position: absolute;
    top: 15px;
    height: 50px;
    left: 0;
    right: 0;
  }
}

nav.navigation .bar .nav-content .right .search-link .search-trigger {
  position: absolute;
  right: 8px;
  opacity: 1;
  transition: opacity .3s;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  padding-right: 30px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  nav.navigation .bar .nav-content .right .search-link .search-trigger {
    position: absolute;
    top: -5px;
    right: 0;
  }
}

nav.navigation .bar .nav-content .right .search-link .search-trigger .search-open, nav.navigation .bar .nav-content .right .search-link .search-trigger .search-close {
  position: absolute;
  right: 15px;
  transition: opacity .3s;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  nav.navigation .bar .nav-content .right .search-link .search-trigger .search-open, nav.navigation .bar .nav-content .right .search-link .search-trigger .search-close {
    height: 22px;
    top: 30px;
  }
}

nav.navigation .bar .nav-content .right .search-link .search-trigger .search-open {
  opacity: 1;
  z-index: 50;
}

nav.navigation .bar .nav-content .right .search-link .search-trigger .search-close {
  opacity: 0;
  z-index: 49;
}

nav.navigation .bar .nav-content .right .search-link .field {
  position: absolute;
  right: 30px;
  opacity: 0;
  pointer-events: none;
  margin-right: 30px;
  height: 100%;
  width: 300px;
  display: flex;
  align-items: center;
  transition: opacity .3s;
  z-index: 22;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  nav.navigation .bar .nav-content .right .search-link .field {
    position: relative;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  nav.navigation .bar .nav-content .right .search-link .field {
    width: 230px;
  }
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .search-link .field {
    right: 0;
    width: 100%;
    opacity: 1;
    margin: 0;
    padding: 0 20px;
  }
}

nav.navigation .bar .nav-content .right .search-link .field .search-open {
  position: absolute;
  transform: translateX(20px);
  pointer-events: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  nav.navigation .bar .nav-content .right .search-link .field .search-open {
    position: relative;
    left: 10px;
  }
}

nav.navigation .bar .nav-content .right .search-link input {
  font-family: 'Flama Basic', sans-serif;
  height: 75%;
  border-radius: 6px;
  outline: none;
  border: none;
  background-color: #F6F8F9;
  width: 100%;
  font-size: 16px;
  padding: 0px 0px 0px 50px;
}

nav.navigation .bar .nav-content .right .search-link input:focus {
  box-shadow: 0 0 0 1px rgba(138, 139, 140, 0.2);
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .search-link input {
    pointer-events: auto;
  }
}

nav.navigation .bar .nav-content .right .search-link.open .field {
  opacity: 1;
  pointer-events: auto;
}

nav.navigation .bar .nav-content .right .search-link.open .search-trigger .search-open {
  position: none;
  opacity: 0;
  z-index: 49;
}

nav.navigation .bar .nav-content .right .search-link.open .search-trigger .search-close {
  position: absolute;
  opacity: 1;
  z-index: 50;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .search-link .search-trigger .search-open, nav.navigation .bar .nav-content .right .search-link .search-trigger .search-close {
    display: none;
  }
}

nav.navigation .bar .nav-content .right a {
  font-size: 16px;
}

nav.navigation .bar .nav-content .right a.in-touch {
  font-family: 'FlamaSemicondensed Medium', sans-serif;
  font-style: normal;
  padding: 0px 40px 0px 40px;
  font-size: 16px;
  border-left: 1px solid rgba(214, 223, 227, 0.5);
  height: 100%;
  display: flex;
  align-items: center;
  transition: background-color .3s, color .3s, border .3s;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right a.in-touch {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100%;
    text-align: center;
    background: linear-gradient(to right, #C53494, #3B94A3);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media all and (max-width: 991.98px) and (-ms-high-contrast: none), (max-width: 991.98px) and (-ms-high-contrast: active) {
  nav.navigation .bar .nav-content .right a.in-touch {
    left: 0;
  }
}

nav.navigation .bar .nav-content .right a.in-touch:hover {
  background-color: #3D4F63;
  color: white;
  border-left: 1px solid #3D4F63;
}

nav.navigation .bar .nav-content .right a.login, nav.navigation .bar .nav-content .right a.logout {
  font-size: 14px;
  opacity: 0.7;
  transition: opacity .3s;
  color: #164A62;
  transition: opacity .3s;
}

nav.navigation .bar .nav-content .right a.login:hover, nav.navigation .bar .nav-content .right a.logout:hover {
  opacity: 1;
  transition: opacity .3s;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right a.login, nav.navigation .bar .nav-content .right a.logout {
    position: absolute;
    bottom: 70px;
    left: 20px;
    z-index: 100;
  }
}

nav.navigation .bar .nav-content .right a.login span, nav.navigation .bar .nav-content .right a.logout span {
  position: relative;
}

nav.navigation .bar .nav-content .right a.login span:before, nav.navigation .bar .nav-content .right a.logout span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #164A62;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

nav.navigation .bar .nav-content .right a.login span:hover:before, nav.navigation .bar .nav-content .right a.logout span:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

nav.navigation .bar .nav-content .right .lang {
  margin-right: 30px;
  position: relative;
  width: 52px;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .lang {
    position: absolute;
    bottom: 130px;
    left: 30px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  nav.navigation .bar .nav-content .right .lang {
    bottom: -3px;
  }
}

@media all and (-ms-high-contrast: none) and (max-width: 991.98px), (-ms-high-contrast: active) and (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .lang {
    bottom: 62px;
  }
}

nav.navigation .bar .nav-content .right .lang img {
  width: auto;
}

nav.navigation .bar .nav-content .right .lang .lang-button {
  padding: 10px;
  background: none;
  transition: background .3s;
  border-radius: 6px;
  font-size: 1.2rem;
}

nav.navigation .bar .nav-content .right .lang .lang-button span {
  font-family: 'Flama Semibold', sans-serif;
  color: #164A62;
  transition: color .3s;
}

nav.navigation .bar .nav-content .right .lang .lang-button svg {
  transform: rotate(0deg);
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .lang .lang-button svg {
    transform: rotate(180deg);
  }
}

nav.navigation .bar .nav-content .right .lang .lang-button svg .st0 {
  fill: rgba(22, 74, 98, 0.3);
  enable-background: new;
}

nav.navigation .bar .nav-content .right .lang .lang-content {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s, transform .3s;
  right: 2px;
  bottom: 0px;
  transform: translateY(100%);
  width: 240px;
  background: #F6F8F9;
  border-radius: 6px;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .lang .lang-content {
    right: auto;
    left: -2px;
    bottom: 410px;
    transform: translateY(106%);
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  nav.navigation .bar .nav-content .right .lang .lang-content {
    bottom: 68px;
    display: none;
  }
}

@media all and (-ms-high-contrast: none) and (max-width: 991.98px), (-ms-high-contrast: active) and (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .lang .lang-content {
    bottom: 410px;
  }
}

nav.navigation .bar .nav-content .right .lang .lang-content a {
  padding: 10px;
  margin: 0px;
  display: block;
  background: none;
  transition: background .3s;
  text-align: left;
}

nav.navigation .bar .nav-content .right .lang .lang-content a:hover {
  background: rgba(59, 148, 163, 0.1);
}

nav.navigation .bar .nav-content .right .lang.open .lang-button {
  background: #3B94A3;
}

nav.navigation .bar .nav-content .right .lang.open .lang-button span {
  color: white;
}

nav.navigation .bar .nav-content .right .lang.open .lang-button svg {
  transform: rotate(180deg);
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .right .lang.open .lang-button svg {
    transform: rotate(0deg);
  }
}

nav.navigation .bar .nav-content .right .lang.open .lang-button svg .st0 {
  fill: rgba(255, 255, 255, 0.3);
}

nav.navigation .bar .nav-content .right .lang.open .lang-content {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(106%);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  nav.navigation .bar .nav-content .right .lang.open .lang-content {
    display: block;
  }
}

nav.navigation .bar .nav-content .left,
nav.navigation .bar .nav-content .right {
  position: absolute;
}

@media (max-width: 991.98px) {
  nav.navigation .bar .nav-content .left,
  nav.navigation .bar .nav-content .right {
    position: static;
  }
}

@media all and (max-width: 991.98px) and (-ms-high-contrast: none), (max-width: 991.98px) and (-ms-high-contrast: active) {
  nav.navigation .bar .nav-content .left,
  nav.navigation .bar .nav-content .right {
    position: initial;
  }
}

nav.navigation .bar.open .nav-button {
  background: #F6F8F9;
}

nav.navigation .bar.open .nav-button svg.ham {
  opacity: 0;
}

nav.navigation .bar.open .nav-button svg.x {
  opacity: 1;
}

nav.navigation .bar.open .nav-content {
  display: flex;
  opacity: 1;
}

nav.navigation .bar a {
  color: #164A62;
  text-decoration: none;
}

nav.navigation .bar a .logo {
  width: 170px;
  padding: 15px 0;
}

nav.navigation .bar a .logo .st0 {
  fill: #3B94A3;
}

nav.navigation .bar a .logo .st1 {
  opacity: 0.7;
  fill: #3B94A3;
}

nav.navigation .bar a .logo .logo-text {
  opacity: 1;
  transition: opacity .3s;
}

nav.navigation .bar .search-open {
  font-size: 16px;
}

nav.navigation .bar .search-close {
  font-size: 21px;
}

@media (min-width: 991px) and (max-width: 1199px) {
  nav.navigation.search-open .bar .right a.in-touch {
    background-color: #3D4F63;
    color: white;
    border-left: 1px solid #3D4F63;
  }
  nav.navigation.search-open .bar .right a.in-touch:hover {
    background-color: white;
    color: #164A62;
    border-left: 1px solid rgba(214, 223, 227, 0.5);
  }
  nav.navigation.search-open .bar .right a.login {
    opacity: 0;
    pointer-events: none;
  }
}

nav.navigation.scrolled .overlay.show + .bar.customer .nav-content .right a.in-touch {
  color: #FFFFFF;
}

nav.navigation.scrolled .overlay.show + .bar.customer .nav-content .right a:hover.in-touch {
  color: #164A62;
}

nav.navigation.scrolled .bar .left .text-links .main-link, nav.navigation.scrolled .bar .right .text-links .main-link {
  transform: translateX(-120px);
  transition-delay: .3s;
}

nav.navigation.scrolled .bar .right a.in-touch {
  background-color: #3D4F63;
  color: white;
  border-left: 1px solid #3D4F63;
}

nav.navigation.scrolled .bar .right a.in-touch:hover {
  background-color: white;
  color: #164A62;
  border-left: 1px solid rgba(214, 223, 227, 0.5);
}

nav.navigation.scrolled .bar .right a.login {
  opacity: 0;
  pointer-events: none;
}

nav.navigation.scrolled a .logo .logo-text {
  opacity: 0;
  pointer-events: none;
}

nav.navigation .menu {
  position: fixed;
  top: 70px;
  background-color: #FFFFFF;
  z-index: -1;
  height: auto;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-10%);
  transition: opacity .3s, transform .3s, visibility .3s;
}

nav.navigation .menu.row {
  margin: 0;
}

@media (max-width: 991.98px) {
  nav.navigation .menu {
    transform: translateY(0%);
    transform: translateX(10%);
  }
}

nav.navigation .menu .sec {
  padding-bottom: 15px;
}

@media (max-width: 991.98px) {
  nav.navigation .menu .sec {
    padding-top: 10px;
    height: calc(100% - 50px);
  }
}

@media (max-width: 767.98px) {
  nav.navigation .menu .sec {
    height: 100%;
  }
}

nav.navigation .menu .sec.opaque {
  background: rgba(246, 248, 249, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 991.98px) {
  nav.navigation .menu .sec.opaque {
    justify-content: flex-start;
  }
}

nav.navigation .menu .sec a {
  font-size: 16px;
  text-decoration: none;
  color: #3D4F63;
  display: block;
}

nav.navigation .menu .sec .links-drawer {
  display: flex;
}

@media (max-width: 991.98px) {
  nav.navigation .menu .sec .links-drawer {
    width: 100%;
    flex-direction: column;
  }
}

nav.navigation .menu .sec .links-drawer:hover .links a {
  opacity: .6;
}

@media (max-width: 991.98px) {
  nav.navigation .menu .sec .links-drawer .links {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  nav.navigation .menu .sec .links-drawer .sub-links {
    width: 100%;
  }
}

nav.navigation .menu .sec .links-drawer .links a {
  font-family: 'Flama Basic', sans-serif;
  opacity: 1;
  transition: opacity .3s;
  display: flex;
  align-items: center;
  position: relative;
}

nav.navigation .menu .sec .links-drawer .links a > svg {
  position: absolute;
  top: 20px;
  right: 15px;
  opacity: 0.2;
  width: 8px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  nav.navigation .menu .sec .links-drawer .links a > svg {
    top: 0;
  }
}

@media (max-width: 991.98px) {
  nav.navigation .menu .sec .links-drawer .links a > svg {
    top: 14px;
    right: 15px;
    transform: rotate(90deg);
  }
}

nav.navigation .menu .sec .links-drawer .links a > svg path {
  fill: #164A62;
}

nav.navigation .menu .sec .links-drawer .links a > svg:hover > svg {
  opacity: 0.7;
}

nav.navigation .menu .sec .links-drawer .links a.selected {
  opacity: 1;
}

nav.navigation .menu .sec .links-drawer .links a.selected .link-body {
  background: linear-gradient(to right, rgba(207, 44, 152, 0.06), rgba(82, 192, 211, 0.06));
}

nav.navigation .menu .sec .links-drawer .links a.selected .link-body:after {
  opacity: 1;
}

nav.navigation .menu .sec .links-drawer .links a.selected .link-body img {
  opacity: 1;
}

nav.navigation .menu .sec .links-drawer .links a span {
  display: block;
}

nav.navigation .menu .sec .links-drawer .links a .link-body {
  padding: 15px 20px;
  transition: border .3s, background .3s;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background: none;
}

@media (max-width: 991.98px) {
  nav.navigation .menu .sec .links-drawer .links a .link-body {
    padding: 12px 15px;
  }
}

nav.navigation .menu .sec .links-drawer .links a .link-body img {
  position: absolute;
  right: 0px;
  opacity: 0;
  transition: opacity .3s;
}

nav.navigation .menu .sec .links-drawer .sub-links {
  position: relative;
}

nav.navigation .menu .sec .links-drawer .sub-links .drawer {
  padding: 0 10px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s;
  position: absolute;
  top: 0px;
  top: calc(100% - 51px);
  left: 0px;
  right: initial;
  width: 200px;
  width: max-content;
  height: auto;
}

@media (max-width: 991.98px) {
  nav.navigation .menu .sec .links-drawer .sub-links .drawer {
    padding-left: 1.5rem;
    top: 0;
  }
}

nav.navigation .menu .sec .links-drawer .sub-links .drawer.open {
  opacity: 1;
  pointer-events: auto;
}

nav.navigation .menu .sec .links-drawer .sub-links .drawer:hover a {
  opacity: .5;
}

nav.navigation .menu .sec .links-drawer .sub-links .drawer a {
  font-size: 16px;
  padding: 15px 10px;
  opacity: 1;
  transition: opacity .3s;
  font-family: 'Flama Light', sans-serif;
}

nav.navigation .menu .sec .links-drawer .sub-links .drawer a:hover {
  opacity: 1;
}

nav.navigation .menu .sec .links-drawer .sub-links .drawer a span {
  opacity: .5;
}

nav.navigation .menu .sec .links {
  height: 100%;
}

nav.navigation .menu .sec .links.def a {
  margin-bottom: 2rem;
  opacity: 1;
  transition: opacity .3s;
}

nav.navigation .menu .sec .links.def a:hover {
  opacity: .5;
}

nav.navigation .menu .gradient {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 15px;
  background: linear-gradient(45deg, #3B94A3, #C53494);
}

@media (max-width: 991.98px) {
  nav.navigation .menu .gradient {
    display: none;
  }
}

nav.navigation .menu .menu-aim-overflow {
  position: absolute;
  bottom: -150px;
  height: 150px;
  width: 100%;
  opacity: 0;
}

nav.navigation .menu .mobile-menu-return {
  display: none;
  font-size: 18px;
  padding: 10px 15px;
  color: #fff;
  background: linear-gradient(45deg, #CF2C98, #52C0D3);
}

nav.navigation .menu .mobile-menu-return svg {
  margin-right: 10px;
}

@media (max-width: 991.98px) {
  nav.navigation .menu .mobile-menu-return {
    display: flex;
    align-items: center;
    height: 50px;
  }
}

@media all and (max-width: 991.98px) and (-ms-high-contrast: none), (max-width: 991.98px) and (-ms-high-contrast: active) {
  nav.navigation .menu .mobile-menu-return {
    height: 50px;
  }
}

nav.navigation .overlay {
  background: #3D4F63;
  opacity: 0;
  position: fixed;
  top: 70px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: opacity .3s;
  z-index: -1;
}

nav.navigation .overlay.show {
  pointer-events: auto;
  opacity: .6;
  transition-delay: .1s;
  z-index: 998;
}

@media (max-width: 991.98px) {
  nav.navigation .overlay {
    display: none !important;
    top: 50px;
  }
}

.sfPageWrapper nav.navigation .bar {
  position: static !important;
}
