@import "../../bootstrap/wls-bootstrap/functions";
@import "../../bootstrap/wls-bootstrap/variables";
@import "../../bootstrap/wls-bootstrap/mixins/breakpoints";
@import "shared/variables";
@import "shared/fonts";

//This hides the extra area above the menu -- need to refactor on update
.header-top-area {
  display: none;
}

@media (min-width: 1200px) {
  .main_menu_area {
    min-height: 70px!important;  
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .main_menu_area {
    min-height: 70px!important;
  }
}
@media (max-width: 991px) and (min-width: 1px) {
  .main_menu_area {
      min-height: 50px!important;
  }
}

nav.navigation {
  // .overlay.show + .bar.customer {
  //   background-color: rgba(255, 255, 255, 1);
  //   a {
  //     .logo .st0, .logo .st1 {
  //       fill: $teal;
  //     }
  //   }
  //   .bar-bg {
  //     background: $white;
  //   }
  //   .nav-content {
  //     .left .main-link, .right .main-link {
  //       color:$dark-teal;
  //       & > span:before {
  //         background-color: $dark-teal;
  //        }
  //     }
  //     .right {
  //       .search-link .search-trigger {
  //         .search-open, .search-close {
  //           color:$dark-teal;
  //         }
  //       }
  //       a {
  //         &.login, &.logout {
  //           color:$teal;
  //         }
  //         &.in-touch {
  //           color:$dark-teal;

  //           @include media-breakpoint-down(md) {
  //             color:$white;
  //           }
  //         }
  //         &:hover.in-touch {
  //           color:$white;
  //         }
  //       }
  //     }
  //   }
  // }

  .bar {
    position      : fixed;
    top           : 0px;
    left          : 0px;
    width         : 100%;
    height        : 70px;
    background    : white;
    z-index       : 998;
    display       : flex;
    align-items   : center;
    border-bottom : 1px solid rgba(195, 197, 200, .2);

    @include media-breakpoint-down(md) {
      height: 50px;
    }

    .nav-button {
      display:none;
      position: absolute;
      top:0px;
      right: 0;
      height          : 49px;
      width           : 50px;
      background      : $dark-teal;
      border          : none;
      outline         : none;
      display         : flex;
      justify-content : center;
      align-items     : center;
      transition      : background .3s;
      z-index         : 999; 

      svg {
        position   : absolute;
        transition : opacity .3s;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          top: 0px;
          right:15px;
        }

        &.ham {
          opacity: 1;
        }

        &.x {
          opacity: 0;
        }
      }

      @include media-breakpoint-up(lg) {
        display:none;
      }
    }

    .bar-bg {
      position   : absolute;
      width      : 100%;
      height     : 100%;
      background : white;
      top        : 0px;
      left       : 0px;
    }

    .mcui-brand {
      position : absolute;
      top: -2px;
      left: 40px;
      display: flex;
      align-items: center;
      height: 70px;

      @include media-breakpoint-down(lg) {
        top: 0;
        left: 10px;
      }

      @include media-breakpoint-down(md) {
        height:50px;
        left: 20px;
        top: 0;
      }
    }

    .nav-content {
      height:100%;

      @include media-breakpoint-down(md) {
        display:none;
        position: fixed;
        top: 50px;
        flex-direction: column;
        width: 100%;
        height: 100%;
        top: 50px;
        background: white;
        opacity: 0;
        transition: opacity .3s;
      }

      .left {
        left        : 240px;
        height      : 70px;
        display     : flex;
        align-items : center;

        @include media-breakpoint-down(lg) {
          left: 185px;
        }

        @include media-breakpoint-down(md) {
          position: relative!important;
          top: 50px;
          left: 0;
          height: auto;
          width: 100%;
        }

        .text-links {
          display     : inline-block;
          height      : 100%;
          display     : flex;
          align-items : center;
          transition  : opacity .3s;

          @include media-breakpoint-down(md) {
            flex-direction: column;
            width: 100%;
            align-items : flex-start;
          }

          .section-container {
            height: 100%;

            @include media-breakpoint-down(md) {
              width: 100%;

              .menu {
                  min-height: 1px;
                  height: 100%;
                  top: 50px;
                  z-index:999;
                  overflow:scroll;
                  background-color: $white;
                }
            }

            & > a {
              @include flamasemi-semibold;
            }

            &.open {
              .menu {
                visibility       : visible;
                opacity          : 1;
                pointer-events   : auto;
                transform        : translateY(0%);
                transition: opacity .3s, transform .3s, visibility 0s;

                @include media-breakpoint-down(md) {
                  transform: translateX(0%);
                  width: 100%;
                }
              }

              .main-link {
                opacity: 1;
                
                &>span:before {
                  visibility: visible;
                  transform: scaleX(1);
                }
              }
            }

          }
        }

        // .scrolled-links {
        //   opacity        : 0;
        //   pointer-events : none;
        //   transition     : opacity .3s;
        //   position       : absolute;
        //   left           : 8rem;
        //   height         : 100%;

        //   .section-dropdown {
        //     display : flex;
        //     height  : 100%;

        //     .section-link {
        //       opacity      : 1;
        //       margin-right : 15px;
        //     }

        //     .dropdown-container {
        //       display  : flex;
        //       position : relative;

        //       &:hover {
        //         .dropdown-content {
        //           opacity        : 1;
        //           pointer-events : auto;
        //           transform      : translateY(95%);
        //         }
        //       }
        //     }

        //     .dropdown {
        //       margin-left : 15px;
        //       font-size   : 1.5rem;
        //       font-weight : 300;
        //       opacity     : 1;
        //       white-space : nowrap;

        //       svg {
        //         margin-left : 5px;
        //       }
        //     }

        //     .dropdown-content {
        //       position       : absolute;
        //       bottom         : 0px;
        //       right          : 0px;
        //       transform      : translateY(85%);
        //       opacity        : 0;
        //       transition     : opacity .3s, transform .3s;
        //       pointer-events : none;
        //       width          : 150px;
        //       background     : #F6F8F9;
        //       padding        : 15px 0px;
        //       border-radius  : 6px;

        //       a {
        //         padding     : 5px;
        //         margin      : 0px;
        //         display     : block;
        //         background  : none;
        //         transition  : background .3s, color .3s;
        //         text-align  : center;
        //         font-size   : 1.4rem;
        //         font-weight : 300;

        //         &:hover {
        //           background : rgba(59, 148, 163, .1);
        //         }
        //       }
        //     }

        //     .jump-links {
        //       display        : flex;
        //       align-items    : center;
        //       margin-left    : 50px;
        //       opacity        : 1;
        //       pointer-events : auto;
        //       transition     : opacity .3s;

        //       &.hide {
        //         opacity        : 0;
        //         pointer-events : none;
        //       }

        //       a {
        //         margin      : 0px 10px;
        //         display     : flex;
        //         align-items : center;
        //         opacity     : 1;
        //         transition  : opacity .3s;
        //         text-align  : center;
        //         font-size   : 1.4rem;
        //         font-weight : 300;
        //         height      : 100%;
        //         position    : relative;

        //         &:after {
        //           content    : '';
        //           position   : absolute;
        //           bottom     : 0px;
        //           width      : 100%;
        //           height     : 3px;
        //           background : linear-gradient(to right, #CF2C98, #52C0D3);
        //           opacity    : 0;
        //           transition : opacity .3s;
        //           transform  : translateY(2px);
        //         }

        //         &:hover {
        //           &:after {
        //             opacity: 1;
        //           }
        //         }

        //         &.active {
        //           &:after {
        //             opacity: 1;
        //           }
        //         }
        //       }
        //     }
        //   }
        // }

        .main-link {
          @include flamasemi-semibold;
          font-style: normal;
          color: $dark-teal;
          cursor: pointer;
          font-size   : 16px;
          transition  : opacity .3s;
          display     : flex;
          align-items : center;
          opacity: 0.7;
          position:relative;
          height           : 100%;
          padding          : 0px 20px;
          transition       : transform .3s, opacity .3s;
          transition-delay : 0s;

          @include media-breakpoint-down(md) {
            font-size   : 16px;
          }

          &>svg {
            position: relative;
            transform: rotate(90deg);
            opacity: 0.2;
            right: -10px;

            @include media-breakpoint-down(md) {
              transform: rotate(0deg);
              opacity: 0.6;
              position:absolute;
              right: 30px;
              // top: 10px;
              display: flex;
              align-self: center;
            }
          }

          @include media-breakpoint-down(md) {
            height: 50px;
            font-size   : 2rem;
          }

          &>span {
            position:relative;

            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 2px;
              bottom: -5px;
              left: 0;
              background-color: #164A62;
              visibility: hidden;
              transform: scaleX(0);
              transition: all 0.3s ease-in-out 0s;
            }
          }

          &:hover>span:before {
            visibility: visible;
            transform: scaleX(1);
          }

          &:hover {
            opacity: 1;

            &>svg {
              opacity: 0.6;
            }
          }

          &.home {
            padding   : 0px 20px 0px 0px;
            transform : translateY(-16%);
          }
        }
      }

      .right {
        right       : 0px;
        height      : 70px;
        display     : flex;
        align-items : center;

        @include media-breakpoint-down(lg) {
          right: 5px;
        }

        @include media-breakpoint-down(md) {
          flex-direction: column;
          height:50px;
        }

        .section-container {
          height: 100%;

          @include media-breakpoint-down(md) {
            position: absolute;
            top: 325px;
          }

          .user.menu {
            right: 50px;

            @include media-breakpoint-down(md) {
              right: 0;
            }
          }
          @include media-breakpoint-down(md) {
            width: 100%;

          .menu {
              min-height: 1px;
              height: 100%;
              top: 50px;
              z-index:999;
              overflow:scroll;
            }
          }

          & > a {
            @include flamasemi-semibold;
          }

          &.open {
            .menu {
              visibility       : visible;
              opacity          : 1;
              pointer-events   : auto;
              transform        : translateY(0%);
              transition: opacity .3s, transform .3s, visibility 0s;

              @include media-breakpoint-down(md) {
                transform: translateX(0%);
                width: 100%;
              }
            }

            .main-link {
              opacity: 1;
              
              &>span:before {
                visibility: visible;
                transform: scaleX(1);
              }
            }
          }
        }

        .main-link {
          @include flamasemi-semibold;
          font-style: normal;
          color: $dark-teal;
          cursor: pointer;
          font-size   : 16px;
          transition  : opacity .3s;
          display     : flex;
          align-items : center;
          opacity: 0.7;
          position:relative;
          height           : 100%;
          padding          : 0px 20px;
          transition       : transform .3s, opacity .3s;
          transition-delay : 0s;

          @include media-breakpoint-down(md) {
            font-size   : 14px;
          }

          &>svg {
            position: relative;
            transform: rotate(90deg);
            opacity: 0.2;
            right: -10px;

            @include media-breakpoint-down(md) {
              transform: rotate(0deg);
              opacity: 0.6;
              position:absolute;
              right: 30px;
              // top: 10px;
              display: flex;
              align-self: center;
            }
          }

          @include media-breakpoint-down(md) {
            height: 50px;
            font-size   : 2rem;
          }

          &>span {
            position:relative;

            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 2px;
              bottom: -5px;
              left: 0;
              background-color: #164A62;
              visibility: hidden;
              transform: scaleX(0);
              transition: all 0.3s ease-in-out 0s;
            }
          }

          &:hover>span:before {
            visibility: visible;
            transform: scaleX(1);
          }

          &:hover {
            opacity: 1;

            &>svg {
              opacity: 0.6;
            }
          }

          &.home {
            padding   : 0px 20px 0px 0px;
            transform : translateY(-16%);
          }
        }

        .search-link {
          transform   : translateX(0px);
          transition  : transform .3s;
          position    : relative;
          height      : 100%;
          display     : flex;
          align-items : center;

          @include media-breakpoint-down(md) {
            position: absolute;
            top: 15px;
            height: 50px;
            left: 0;
            right: 0;
          }

          .search-trigger {
            position : absolute;
            right    : 8px;
            opacity     : 1;
            transition  : opacity .3s;
            display     : flex;
            align-items : center;
            height: 100%;
            cursor: pointer;
            padding-right: 30px;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              position: absolute;
              top: -5px;
              right:0;
            }

            .search-open, .search-close {
              position:absolute;
              right: 15px;
              transition     : opacity .3s;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                height: 22px;
                top: 30px;
              }
            }
            .search-open {
              opacity:1;
              z-index:50;
            }
            .search-close {
              opacity:0;
              z-index:49;
            }
          }

          .field {
            position : absolute;
            right    : 30px;
            opacity        : 0;
            pointer-events : none;
            margin-right   : 30px;
            height         : 100%;
            width          : 300px;
            display        : flex;
            align-items    : center;
            transition     : opacity .3s;
            z-index: 22;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              position: relative;
            }

            @media (min-width: 991px) and (max-width: 1199px) {
              width: 230px;
            }

            @include media-breakpoint-down(md) {
              right: 0;
              width: 100%;
              opacity: 1;
              margin: 0;
              padding: 0 20px;
            }

            .search-open {
              position       : absolute;
              transform      : translateX(20px);
              pointer-events : none;

              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                position:relative;
                left: 10px;
              }
            }
          }

          input {
            @include flama-basic;
            height           : 75%;
            border-radius    : 6px;
            outline          : none;
            border           : none;
            background-color : $light-gray-web;
            width            : 100%;
            font-size        : 16px;
            padding          : 0px 0px 0px 50px;

            &:focus {
              box-shadow: 0 0 0 1px rgba(138,139,140,0.2);
            }

            @include media-breakpoint-down(md) {
              pointer-events: auto;
            }
          }

          &.open {
            .field {
              opacity        : 1;
              pointer-events : auto;
            }

            .search-trigger .search-open {
              position : none;
              opacity  : 0;
              z-index: 49;
            }

            .search-trigger .search-close {
              position : absolute;
              opacity  : 1;
              z-index: 50;
            }
          }

          .search-trigger .search-open, .search-trigger .search-close {
            @include media-breakpoint-down(md) {
              display:none;
            }
          }
        }

        a {
          font-size   : 16px;

          &.in-touch {
            @include flamasemi-medium;
            padding     : 0px 40px 0px 40px;
            font-size   : 16px;
            border-left : 1px solid rgba(214, 223, 227, .5);
            height      : 100%;
            display     : flex;
            align-items : center;
            transition  : background-color .3s, color .3s, border .3s;

            @include media-breakpoint-down(md)  {
              position: fixed;
              bottom: 0;
              height: 50px;
              width: 100%;
              text-align: center;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                left:0;
              }

              background: linear-gradient(to right, $purple, $teal);
              color: white;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &:hover {
              background-color : $charcoal;
              color            : white;
              border-left      : 1px solid $charcoal;
            }
          }

          &.login, &.logout {
            font-size  : 14px;
            opacity    : 0.7;
            transition : opacity .3s;
            color: $dark-teal;
            transition: opacity .3s;

            &:hover {
              opacity: 1;
              transition: opacity .3s;
            }

            @include media-breakpoint-down(md) {
              position: absolute;
              bottom: 70px;
              left: 20px;
              z-index: 100;
            }

            span {
              position: relative;

              &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: -5px;
                left: 0;
                background-color: $dark-teal;
                visibility: hidden;
                transform: scaleX(0);
                transition: all 0.3s ease-in-out 0s;
              }

              &:hover:before {
                visibility: visible;
                transform: scaleX(1);
              }
            }

          }
        }

        .lang {
          margin-right : 30px;
          position     : relative;
          width        : 52px;

          @include media-breakpoint-down(md) {
            position: absolute;
            bottom: 130px;
            left: 30px;
          }

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            bottom: -3px;
              @include media-breakpoint-down(md) {
                bottom: 62px;
              }
            }

          img {
            width : auto;
          }

          .lang-button {
            padding       : 10px;
            background    : none;
            transition    : background .3s;
            border-radius : 6px;
            font-size     : 1.2rem;

            span {
              @include flama-semibold;
              color      : $dark-teal;
              transition : color .3s;

            }

            svg {
              transform: rotate(0deg);

              @include media-breakpoint-down(md) {
                transform: rotate(180deg);
              }

              .st0 {
                fill              : rgba(22, 74, 98, .3);
                enable-background : new;
              }
            }
          }

          .lang-content {
            position      : absolute;
            opacity       : 0;
            pointer-events: none;
            transition    : opacity .3s, transform .3s;
            right         : 2px;
            bottom        : 0px;
            transform     : translateY(100%);
            width         : 240px;
            background    : $light-gray-web;
            border-radius : 6px;

            @include media-breakpoint-down(md) {
              right: auto;
              left: -2px;
              bottom: 410px;
              transform: translateY(106%);
            }

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              bottom: 68px;
              display: none;
              @include media-breakpoint-down(md) {
                bottom: 410px;
              }
            }

            a {
              padding    : 10px;
              margin     : 0px;
              display    : block;
              background : none;
              transition : background .3s;
              text-align : left;

              &:hover {
                background : rgba(59, 148, 163, .1);
              }
            }
          }

          &.open {
            .lang-button {
              background: #3B94A3;

              span {
                color: white;
              }
              svg {
                transform: rotate(180deg);

                @include media-breakpoint-down(md) {
                  transform: rotate(0deg);
                }

                .st0 {
                  fill: rgba(255, 255, 255, 0.3);
                }
              }
            }

            .lang-content {
              opacity: 1;
              pointer-events: auto;
              transform: translateY(106%);
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: block;
              }
            }
          }
        }
      }

      .left,
      .right {
        position : absolute;

        @include media-breakpoint-down(md) {
          position: static;

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            position: initial;
          }
        }
      }
    }

    &.open {
      .nav-button {
        background: #F6F8F9;

        svg {
          &.ham {
            opacity: 0;
          }

          &.x {
            opacity: 1;
          }
        }
      }

      .nav-content {
        display: flex;
        opacity: 1;
      }
    }

    a {
      color           : $dark-teal;
      text-decoration : none;

      .logo {
        width : 170px;
        padding: 15px 0;

        .st0 {
          fill : #3B94A3;
        }

        .st1 {
          opacity : 0.7;
          fill    :#3B94A3;
        }

        .logo-text {
          opacity    : 1;
          transition : opacity .3s;
        }
      }
    }
    
    .search-open {
      font-size: 16px;
    }

    .search-close {
      font-size: 21px;
    }
  }

  &.search-open {
    @media (min-width: 991px) and (max-width: 1199px) {
      .bar {
        .right {
          // .search-link {
          //   transform : translateX(140px);
          // }

          a {
            &.in-touch {
              background-color : #3D4F63;
              color            : white;
              border-left      : 1px solid #3D4F63;

              &:hover {
                background-color : white;
                color            : #164A62;
                border-left      : 1px solid rgba(214, 223, 227, .5);
              }
            }

            &.login {
              opacity        : 0;
              pointer-events : none;
            }
          }
        }
      }
    }
  }

  &.scrolled {
    .overlay.show + .bar.customer .nav-content .right {
      a {
        &.in-touch {
          color:$white;
        }
        &:hover.in-touch {
          color:$dark-teal;
        }
      }
    }

    .bar {
      .left, .right {
        .text-links .main-link {
          transform        : translateX(-120px);
          transition-delay : .3s;
        }
      }

      .right {
        // .search-link {
        //   transform        : translateX(140px);
        //   transition-delay : .3s;
        // }

        a {
          &.in-touch {
            background-color : $charcoal;
            color            : white;
            border-left      : 1px solid $charcoal;

            &:hover {
              background-color : white;
              color            : $dark-teal;
              border-left      : 1px solid rgba(214, 223, 227, .5);
            }
          }

          &.login {
            opacity        : 0;
            pointer-events : none;
          }
        }
      }
    }

    a {
      .logo {
        .logo-text {
          opacity        : 0;
          pointer-events : none;
        }
      }
    }
  }

  .menu {
    position       : fixed;
    top            : 70px;
    background-color: $white;
    z-index        : -1;
    height         : auto;
    pointer-events : none;
    visibility     : hidden;
    opacity        : 0;
    transform      : translateY(-10%);
    transition     : opacity .3s, transform .3s, visibility .3s;

    &.row {
      margin:0;
    }


    @include media-breakpoint-down(md) {
      transform: translateY(0%);
      transform: translateX(10%);
    }

    .sec {
      padding-bottom: 15px;

      @include media-breakpoint-down(md) {
        padding-top: 10px;
        height: calc(100% - 50px);
      }
      @include media-breakpoint-down(sm) {
        height: 100%;
      }

      &.opaque {
        background      : rgba(246, 248, 249, .9);
        display         : flex;
        flex-direction  : column;
        justify-content : center;

        @include media-breakpoint-down(md) {
          justify-content : flex-start;
        }
      }

      a {
        font-size       : 16px;
        text-decoration : none;
        color           : $charcoal;
        display         : block;
      }

      // .title {
      //   @include flama-basic;
      //   color          : $charcoal;
      //   font-size      : 1.1rem;
      //   letter-spacing : .2rem;
      //   margin-bottom  : 1.5rem;
      //   opacity        : .7;
      // }

      .links-drawer {
        display : flex;

        @include media-breakpoint-down(md) {
          width: 100%;
          flex-direction: column;
        }

        &:hover {
          .links a {
            opacity: .6;
          }
        }

        .links {
          @include media-breakpoint-down(md) {
            width:100%;
          }
        }
        .sub-links {
          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }

        .links {

          a {
            @include flama-basic;
            opacity        : 1;
            transition     : opacity .3s;
            display        : flex;
            align-items    : center;
            position: relative;

            & > svg {
              position: absolute;
              top: 20px;
              right: 15px;
              opacity: 0.2;
              width: 8px;

              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                top: 0;
              }

              @include media-breakpoint-down(md) {
                top: 14px;
                right: 15px;
                transform: rotate(90deg);
              }

              path {
                fill: $dark-teal;
              }

              &:hover {
                & > svg {
                  opacity: 0.7;
                }
              }
            }

            &.selected {
              opacity: 1;

              .link-body {
                background: linear-gradient(to right, rgba(207, 44, 152, 0.06), rgba(82, 192, 211, .06));

                &:after {
                  opacity: 1;
                }

                img {
                  opacity: 1;
                }
              }
            }

            span {
              display: block;
            }

            .link-body {
              // @include flama-basic;
              padding: 15px 20px;
              transition     : border .3s, background .3s;
              width          : 100%;
              position       : relative;
              display        : flex;
              align-items    : center;
              background     : none;

              @include media-breakpoint-down(md) {
                padding: 12px 15px;
              }

              img {
                position   : absolute;
                right      : 0px;
                opacity    : 0;
                transition : opacity .3s;
              }
            }
          }
        }

        .sub-links {
          position: relative;

          .drawer {
            padding: 0 10px;
            opacity        : 0;
            pointer-events : none;
            transition     : opacity .3s;
            position       : absolute;
            top: 0px;
            top            : calc(100% - 51px);;
            left           : 0px;
            right: initial;
            width: 200px;
            width: max-content;
            height: auto;

            @include media-breakpoint-down(md) {
              padding-left: 1.5rem;
              top: 0;
            }

            &.open {
              opacity        : 1;
              pointer-events : auto;
            }

            &:hover {
              a {
                opacity: .5;
              }
            }

            a {
              font-size: 16px;
              padding: 15px 10px;
              opacity        : 1;
              transition     : opacity .3s;
              font-family: 'Flama Light', sans-serif;

              &:hover {
                opacity: 1;
              }

              span {
                opacity: .5;
              }
            }
          }
        }
      }

      .links {
        height: 100%;

        &.def {
          a {
            margin-bottom : 2rem;
            opacity       : 1;
            transition    : opacity .3s;

            &:hover {
              opacity: .5;
            }
          }
        }
      }
    }

    .gradient {
      position : absolute;
      bottom   : 0px;
      left     : 0px;
      width    : 100%;
      height   : 15px;
      background: linear-gradient(45deg, $teal, $purple);

      @include media-breakpoint-down(md) {
        display:none;
      }
    }

    .menu-aim-overflow {
      position: absolute;
      bottom: -150px;
      height: 150px;
      width: 100%;
      opacity: 0;
    }

    .mobile-menu-return {
      display: none;
      font-size: 18px;
      padding: 10px 15px;
      color: #fff;

      background: linear-gradient(45deg, #CF2C98, #52C0D3);

      svg {
        margin-right: 10px;
      }

      @include media-breakpoint-down(md) {
        display:flex;
        align-items: center;
        height: 50px;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            height: 50px;
          }
      }
    }
  }

  .overlay {
    background     : $charcoal;
    opacity        : 0;
    position       : fixed;
    top            : 70px;
    left           : 0px;
    width          : 100%;
    height         : 100%;
    pointer-events : none;
    transition     : opacity .3s;
    z-index        : -1;
    &.show {
      pointer-events : auto;
      opacity          : .6;
      transition-delay : .1s;
      z-index        : 998;
    }

    @include media-breakpoint-down(md) {
      display:none!important;
      top            : 50px;
    }
  }
}


// Sitefinity back end fix
.sfPageWrapper nav.navigation .bar {
  position: static!important;
}